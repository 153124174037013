import React from 'react';
import Container from 'container';
import ButtonV2 from 'button-v2';
import Img from 'img';
import CustomHeadlineA from '../../shared/section-headlines/custom-headline-a';
import s from './text_image_lp_b.module.scss';

export default function textImageFlipA({
  title,
  subtitle,
  paragraphs,
  button,
  image,
  text_image,
  ...props
}) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.contentWrapper}>
            <CustomHeadlineA
              className={s.customHeadline}
              {...{ title, subtitle }}
            />
            <div className={s.flex}>
              <div className={s.imageWrapper}>
                <Img className={s.image} src={image.title} alt={image.title} />
              </div>
              <div className={s.textWrapper}>
                <div>
                  {paragraphs.map((el, i) => (
                    <p
                    key={`${el.text.slice(0, 1)}-${i}`}
                    className={`italic ${s.text}`}
                    dangerouslySetInnerHTML={{ __html: el.text }}
                  />
                  ))}
                </div>
                <ButtonV2
                  secondary
                  wrapperClass={s.buttonWrapper}
                  buttonClass={s.button}
                  {...{ button, ...props }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
