import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import getTag from 'utils/get-tag';
import s from './flipd.module.scss';

export default function TextImgFlipD({
  title,
  text,
  image,
  image_text,
  button,
  ...props
}) {
  const ImageTextTag = getTag(image_text);
  const TextTag = getTag(text);

  return (
    <Container white className={s.root}>
      <div className={s.wrapper}>
        <div className={s.textWrapper}>
          <div className={s.titleWrapper}>
            <Headline tertiary wild className={s.title} html={title.line1} />
            <Headline tertiary wild className={`${s.title} ${s.line2}`} html={title.line2} />
          </div>
          <TextTag
            className={s.text}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
        <div className={s.card}>
          <div className={s.imgWrapper}>
            <Img className={s.img} src={image.title} alt={image.title} />
          </div>
          <div className={s.cardContent}>
            <div>
              <ImageTextTag
                className={s.imageText}
                dangerouslySetInnerHTML={{ __html: image_text }}
              />
            </div>
            <Button className={s.button} tertiary href={button.href}>
              {button.text}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
}
