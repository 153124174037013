import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import s from './general_hero.module.scss';

export default function GeneralHeroSection({ headline, subtitle1, subtitle2, image }) {
    return (
        <Container className={s.section}>
            <div className={s.wrapper}>
              <Img className={s.image} src={image.title} />
              <Headline className={s.headline} h1 wild secondary html={headline} />
              <Headline className={s.subtitle1} h1 html={subtitle1} />
              <Headline className={s.subtitle2} h1 html={subtitle2} />
            </div>
        </Container>
    )
}