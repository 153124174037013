import React, { useContext } from 'react';
import Button from 'button';
import { navigate } from 'gatsby';
import validate from 'utils/validate';
import { useFormInput, useErrors, useSubmitReady } from '@/hooks/useForm';
import { useSubmitState } from '@/hooks/useSubmitState';
import { lpContext } from 'context/lp-context';
import Spinner from 'shared/spinner';
import axios from 'axios';
import s from './form-for-banner.module.scss';

export default function FormForBanner({
  button_text,
  className,
  type,
  thankyou_slug,
  ...props
}) {
  const lp_options = useContext(lpContext);
  console.log(lp_options, 'context');
  // Inputs
  const name = useFormInput('');
  const email = useFormInput('');

  // Error handlers
  const emailErrors = useErrors({ email: email.state }, validate);
  const nameErrors = useErrors({ name: name.state }, validate);

  // Sumbit handlers
  const submitReady = useSubmitReady(
    {
      name: name.state,
      email: email.state
    },
    validate
  );
  const [disable, submit, submitHandler] = useSubmitState(
    handleSubmit,
    button_text,
    Spinner
  );

  function handleSubmit() {
    const isLP = lp_options !== undefined && lp_options.enable; // Check if this page has LP options
    const payload = {
      name: name.state.value,
      email: email.state.value,
      type: isLP ? 'lp' : type // A flag for the backend, DON"T REMOVE THIS
    };
    if (isLP) {
      payload.campaign = lp_options.campaign; // Add Lp campaign
    }
    console.log(payload, 'payload');
    axios(
      'https://us-central1-laurie-anne-king.cloudfunctions.net/handleFormSubmission',
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        data: payload
      }
    )
      .then(x => {
        console.log(x);
        navigate(isLP ? lp_options.thankyou_slug : thankyou_slug || '/');
      })
      .catch(err => console.log(err));
  }

  return (
    <form className={`${s.form} ${className || ''}`}>
      <input
        type="text"
        name="name"
        placeholder="FULL NAME *"
        value={name.state.value}
        {...name.events}
        className={`${s.input} ${nameErrors.name && 'error'}`}
      />
      <input
        type="text"
        name="email"
        placeholder="EMAIL ADDRESS *"
        value={email.state.value}
        {...email.events}
        className={`${s.input} ${emailErrors.email && 'error'}`}
      />
      <div className={s.buttonWrapper}>
        <Button
          tertiary
          className={s.button}
          disabled={!submitReady || disable}
          onClick={submitHandler}
        >
          {submit}
        </Button>
      </div>
    </form>
  );
}
