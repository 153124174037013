import React from 'react';
import Container from 'container';
import Headline from 'headline';
import s from './headerlp.module.scss';

export default function HeaderLP({ title, subtitle, ...props }) {
  console.log(props, 'OTHER');
  const splittedTitle = title.split(' ');
  const splittedSubtitle = subtitle.split(' ');
  const title1 = splittedTitle.slice(0, 2).join(' ');
  const title2 = splittedTitle.slice(2, 3).join(' ');
  const title3 = splittedTitle.slice(3).join(' ');

  const subtitle1 = splittedSubtitle.slice(0, 3).join(' ');
  const subtitle2 = splittedSubtitle.slice(3).join(' ');
  return (
    <Container className={s.root}>
      <div className={s.titleWrapper}>
        <Headline className={s.normal} h1>{title1}</Headline>
        <Headline className={s.wild} h1 wild>{title2}</Headline>
        <Headline className={s.normal} h1>{title3}</Headline>
      </div>
      <div className={s.subtitleWrapper}>
        <Headline subtitle secondary className={`${s.subtitle}  ${s.first}`} h1 html={subtitle1} />
        <Headline subtitle secondary className={`${s.subtitle} ${s.second}`} h1 html={subtitle2} />
      </div>
    </Container>
  );
}
