import React, { useState, useRef, useEffect } from "react";
import Container from "container";
import Headline from "headline";
import Button from "button";
import Img from "img";
import Icon from "icon";
import Swiper from "react-id-swiper/lib/ReactIdSwiper.full";
import "swiper/dist/css/swiper.css";
import { Navigation, Pagination } from "swiper/dist/js/swiper.esm";
import Embeded from 'shared/embeded';

import s from "./slider.module.scss";

export default function Slider(props) {
  const [swiper, updateSwiper] = useState(null);

  const [playing, setPLaying] = useState(false);
  const [waypointed, setWaypointed] = useState(false);


  console.log('theprops', props);

  const data = props.slide;
  console.log(data);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
      document.querySelector(`#video-hero`).pause();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const params = {
    modules: [Navigation],
    spaceBetween: 30,
    centeredSlides: true,
    //   autoplay: {
    //    delay: 5000,
    //    disableOnInteraction: false,
    //  },
    getSwiper: updateSwiper
  };

  const handleClick = () => {
    if (!playing) {
      document.querySelector('#video-hero').play();
      setPLaying(true);
    } else {
      setPLaying(false);
      document.querySelector('#video-hero').pause();
    }
  };

  useEffect(
    () => {
      if (!waypointed) {
        setWaypointed(true);
        const script = document.createElement('script');
        script.src = 'https://embed.videodelivery.net/embed/r4xu.fla9.latest.js';
        script.setAttribute('data-cfasync', false);
        script.type = 'text/javascript';
        document.head.appendChild(script);
      }
    }, []
  );


  return (
    <Container className={s.section}>
      <Swiper {...params}>
        {data.map((el, idx) => (
          <div key={idx} className={s.slide}>
            <div className={s.textWrapper}>
              <Headline
                wild
                className={s.headline}
                dangerouslySetInnerHTML={{ __html: el.headline }}
              />
              {el.paragraphs.map(li => (
                <p
                  className={s.paragraph}
                  dangerouslySetInnerHTML={{ __html: li.text }}
                />
              ))}
            </div>
            <div className={`${s.imgWrapper} ${idx === 1 ? `${s.imgWrapperB}` : `${null}`} fullWidth`}>
              <Embeded toggle={el.togglevideo.toggle} className={s.video} idx="hero" video={el.togglevideo.video} width="100%" poster={el.togglevideo.video_thumbnail} src={el.image.title} alt={props.alt} />
              <div className={`${s.subtitleWrapper} ${idx === 0 ? `${s.videoSub}` : `${null}`} videoSub`}>
                <p
                  className={`${s.subtitle} ${s.sub1}`}
                  dangerouslySetInnerHTML={{ __html: el.subtitle1 }}
                />
                <p
                  className={`${s.subtitle} ${s.sub2}`}
                  dangerouslySetInnerHTML={{ __html: el.subtitle2 }}
                />
                <Button className={s.button} href={el.href}>
                  {el.button.button_text}
                </Button>
              </div>
            </div>
            <div onClick={() => handleClick()}></div>
          </div>
        ))}
      </Swiper>
      <Icon
        onClick={goPrev}
        icon="slider_arrow"
        className={`${s.icon} ${s.icnLeft}`}
      />
      <Icon
        onClick={goNext}
        icon="slider_arrow"
        className={`${s.icon} ${s.icnright}`}
      />
    </Container>
  );
}
