import React from 'react';
import VideoFrame from 'shared/video-frame-b';
import Img from 'img';

export default function Embeded(props) {
  if (props.toggle === true) {
    return (
      <VideoFrame
        className={props.className}
        idx={props.idx}
        video={props.video}
        poster={props.poster}
      />
    );
    // eslint-disable-next-line no-else-return
  } else {
    return <Img className={props.className} src={props.src} alt={props.alt} />;
  }
}
