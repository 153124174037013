import React, { useState } from 'react';
import Img from 'img';
import replaceDomain from 'utils/replace-domain';

import s from './video-b.module.scss';

export default function VideoFrameBComponent({
  video,
  width,
  height,
  poster,
  idx
}) {
  const [hideThumb, setHideThumb] = useState(false);
  const handleClick = () => {
    setHideThumb(true);
    document.querySelector(`#video-${idx}`).play();
  };

  const resizeScreen = () => {
    document.querySelector('.videoSub').style.display = 'none';
    document.querySelector('.fullWidth').style.width = '101%';
  }

  console.log(idx);
  return (
    <div className={`${s.videoWrapper}`}>
      <div
        className={s.play}
        style={{ display: hideThumb ? 'none' : 'block' }}
        onClick={() => {handleClick(); resizeScreen()}}
      />
      <div
        className={s.videoFrame}
        style={{ width }}
        dangerouslySetInnerHTML={{
          __html: `<stream poster="${replaceDomain(
            poster.url
          )}" src="${video}" controls className={s.videoHero} id="video-${idx}"}></stream>`
        }}
      />
      <div
        className={s.imageWrapper}
        onClick={() => handleClick()}
        style={{ display: hideThumb ? 'none' : 'block' }}
      >
        <Img className={s.image} src={poster.title} />
      </div>
    </div>
  );
}
