import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import s from './blog.module.scss';

export default function Blog({ headline, subtitle, posts, rotate_headline }) {
  return (
    <Container className={s.root}>
      <div className={s.title_wrapper}>
        <Headline wild className={`${s.headline} ${rotate_headline ? s.rotate : ''}`} dangerouslySetInnerHTML={{ __html: headline }} />
        <p className={s.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
      </div>
      <div className={s.posts_wrapper}>
        {posts.map(({ image, title, button, text }, idx) => (
          <div className={s.post}>
            <Img className={`${s.image} ${idx % 2 !== 0 ? `${s.order2}` : 'null'}`} src={image.title} alt={image.title} />
            <div className={`${s.txt_wrapper} ${idx % 2 === 0 ? `${s.end}` : `${s.start}`}`}>
              <h2 className={s.post_title}>{title}</h2>
              <p className={`${s.post_text} ${idx % 2 === 0 ? `${s.txt_right}` : `${s.txt_left}`}`}>{text}</p>
              <Button className={s.button} href={button.href}>{button.button_text}</Button>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}
