import React from 'react';
import { useFormInput, useErrors, useSubmitReady } from '@/hooks/useForm';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import axios from 'axios';
import validate from './validate';
import s from './form.module.scss';

import Spinner from 'shared/spinner';
import { useSubmitState } from '@/hooks/useSubmitState';

export default function Form({ title, subtitle, ...props }) {
  const fullName = useFormInput('');
  const email = useFormInput('');
  const msg = useFormInput('');
  const phone = useFormInput('');
  const fullNameErrors = useErrors({ fullName: fullName.state }, validate);
  const emailErrors = useErrors({ email: email.state }, validate);
  const msgErrors = useErrors({ msg: msg.state }, validate);
  const submitReady = useSubmitReady(
    {
      fullName: fullName.state,
      email: email.state,
      msg: msg.state
    },
    validate
  );

  const [disable, submit, submitHandler] = useSubmitState(handleSubmit, 'Leave Message', Spinner)

  function handleSubmit() {
    //   window.gtag('send', 'event', {
    //     eventCategory: 'Form',
    //     eventAction: 'Submit',
    //     eventLabel: window.location.href,
    //     eventValue: 1
    //   });
    const formBody = {
      fullName: fullName.state.value,
      email: email.state.value,
      msg: msg.state.value,
      phone: phone.state.value,
      type: 'contact' // A flag for the backend, DON"T REMOVE THIS
    };
    axios('https://us-central1-laurie-anne-king.cloudfunctions.net/handleNewsletterForm', { headers: { 'Content-Type': 'application/json' }, method: 'POST', data: formBody }).then(x => {
      console.log(x);
      window.location.href = '/thank-you/';
    }).catch(err => console.log(err));
  }

  return (
    <Container className={s.root}>
      <div className={s.titleWrap}>
        <Headline tertiary wild className={s.title} html={title} />
        <Headline h3 className={s.subtitle} html={title} />
      </div>
      <form className={`${s.form}`} autoComplete="on">
        <div className={s.wrapper}>
          {' '}
          <div className={`${s.inputs}`}>
            <input
              value={fullName.state.value}
              {...fullName.events}
              type="text"
              placeholder="FULL NAME"
              name="fullName"
              className={`${fullNameErrors.fullName && s.error}`}
            />
            <input
              value={email.state.value}
              {...email.events}
              placeholder="EMAIL ADDRESS"
              type="text"
              name="email"
              className={`${emailErrors.email && s.error}`}
            />
            <input
              value={phone.state.value}
              {...phone.events}
              placeholder="PHONE"
              type="tel"
              name="phone"
            />
          </div>
          <div className={s.textArea}>
            <textarea
              name="msg"
              id="msg"
              placeholder="LEAVE A MESSAGE FOR LAURIE-ANNE"
              cols="40"
              rows="6"
              value={msg.state.value}
              {...msg.events}
              className={`${msgErrors.msg && s.error}`}
            />
          </div>
        </div>
        <Button
          className={s.button}
          disabled={!submitReady || disable}
          onClick={submitHandler}
        >
          {submit}
        </Button>
      </form>
    </Container>
  );
}
