import React from 'react';
import PageGenerator from 'components/page-generator';
import Loading from 'shared/loading';
import useWordpress from 'hooks/useWordpress';
import 'styles/project.scss';

export default function PageTemplate({ pageContext }) {
  const context = process.env.GATSBY_DEVELOPMENT
    ? useWordpress(pageContext)
    : pageContext;
  return context ? (
    <main>
      <PageGenerator {...context} />
    </main>
  ) : (
    <Loading />
  );
}
