import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import s from './general-hero-b.module.scss';

export default function GeneralHeroBSection({ headline, subtitle1, subtitle2, image, button  }) {
    return (
        <Container className={s.section}>
            <div className={s.wrapper}>
              <Img className={s.image} src={image.title} />
              <Headline className={s.headline} h1 wild secondary html={headline} />
              <Headline className={s.subtitle1} h1 html={subtitle1} />
              <Headline className={s.subtitle2} h1 html={subtitle2} />
              <Button className={s.button} href={button.href}>
                {button.text}
              </Button>
            </div>
        </Container>
    )
}