import React from 'react';
import Img from 'img';
import Headline from 'headline';
import getTag from 'utils/get-tag';
import Button from 'button';
import s from './card-e.module.scss';

export default function CardE({ image, title, text, button, className, ...props }) {
  const TextTag = getTag(text);
  return (
    <div className={`${s.root} ${className || ''}`}>
      <div className={s.imageWrapper}>
        <Img className={s.img} src={image.title} alt={image.title} />
      </div>
      <div className={s.content}>
        <Headline className={s.title} h3 center html={title.line1} />
        <Headline className={s.title} h3 center html={title.line2} />
        <TextTag
          className={`${s.text} italic`}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <div className={s.buttonWrapper}>
          <Button
            outlined
            tertiary
            href={button.href}
            native
            className={s.button}
          >
            {button.text}
          </Button>
        </div>
      </div>
    </div>
  );
}
