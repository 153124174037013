import React from 'react';
import Container from 'container';
import ButtonV2 from 'button-v2';
import Countdown from 'shared/countdown';
import s from './countdown-lp.module.scss';

export default function CountdownLP({ date, button, ...props }) {
  return (
    <Container className={s.root}>
      <Countdown {...{ date }} />
      <ButtonV2
        secondary
        wrapperClass={s.buttonWrapper}
        buttonClass={s.button}
        {...{ button, ...props }}
      />
    </Container>
  );
}
