import React from 'react';
import Container from 'container';
import Img from 'img';
import Headline from 'headline';
import getTag from 'utils/get-tag';
import Button from 'button';
import s from './card-f.module.scss';

export default function CardF({
  image,
  colored_headline,
  headline,
  text,
  ...props
}) {
  const TextTag = getTag(text);
  return (
    <div className={s.root}>
      <div className={s.imgWrapper}>
        <Img className={s.img} src={image.title} alt={image.title} />
      </div>
      <div className={s.content}>
        <Headline className={`${s.headline} ${s.colored}`} h2 secondary center>
          {colored_headline}
        </Headline>
        <div className={s.textBlock}>
          <Headline className={s.headline} h2 center>
            {headline}
          </Headline>
          <TextTag
            className={`italic ${s.text}`}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </div>
    </div>
  );
}
