import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import Icon from 'icon';
import s from './testimonials-post.module.scss';

export default function TestimonialsPost({ image, icon, title, text, autor }) {
    return (
        <Container className={s.section}>
					<div className={s.wrapper}>
						<div className={s.imgContainer}>
							<Img className={s.image} src={image.title} />
							<Icon icon={icon} />
						</div>
						<div className={s.content}>
							<Headline className={s.title} h2 html={title} />
							<p className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
							<p className={s.autor} dangerouslySetInnerHTML={{ __html: autor }} />
						</div>
					</div>
        </Container>
    )
}