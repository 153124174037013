import React from 'react';
import Container from 'container';
import Img from 'img';
import s from './banner.module.scss';

export default function Bannerection({ images, ...props }) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        {images.map(el => (
          <Img key={el.title} className={s.bannerImg} src={el.image.title} alt={el.image.title} />
        ))}
      </div>
    </Container>
  );
}
