import React from 'react';
import Container from 'container';
import Img from 'img';
import Button from 'button';
import Headline from 'headline';
import s from './image_banner_lp.module.scss';

export default function BannerLpSection({ background_image, icon, title, text, ...props }) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <Img className={s.backgroundImage} src={background_image.title} />
        <div className={s.flexed}>
          <Img className={s.icon} src={icon.title} />
          <Headline className={s.title} h2 tertiary html={title} />
        </div>
        <p className={s.text} dangerouslySetInnerHTML={{__html: text}} />
      </div>
    </Container>
  );
}
