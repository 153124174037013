import React from 'react';
import Container from 'container';
import Img from 'img';
import Headline from 'headline';
import ButtonV2 from 'shared/button-v2';
import s from './hero-lp-a.module.scss';

export default function HeroLPA({ title, button, image, ...props }) {
  const splittedTitle = title.split(' ');
  const title1 = splittedTitle.slice(0, 4).join(' ');
  const title2 = splittedTitle.slice(4, 6).join(' ');
  const title3 = splittedTitle.slice(6).join(' ');
  return (
    <Container className={s.root}>
      <div className={s.imgWrapper}>
        <Img className={s.img} src={image.title} alt={image.title} />
      </div>
      <div className={s.content}>
        <Headline className={s.title} h1 subtitle>
          {title1}
        </Headline>
        <Headline className={`${s.title} ${s.small}`} h1 subtitle>
          {title2}
        </Headline>
        <Headline className={s.title} h1 subtitle>
          {title3}
        </Headline>
        {/* ButtonV2 is a button with a wrapper, that triggers the popup if passed as prop, if not, renders a button, link or a tag depending on data passed */}
        <ButtonV2
          secondary
          wrapperClass={s.buttonWrapper}
          buttonClass={s.button}
          {...{ button, ...props }}
        />
      </div>
    </Container>
  );
}
