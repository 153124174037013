import React, { useState } from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import Icon from 'icon';
import Swiper from 'react-id-swiper/lib/ReactIdSwiper.full';
import 'swiper/dist/css/swiper.css';
import { Navigation, Pagination } from 'swiper/dist/js/swiper.esm';
import s from './slider.module.scss';

export default function Slider(props) {
  const [swiper, updateSwiper] = useState(null);

  const data = props.slides;
  const flip = props.flip;

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const params = {
    modules: [Navigation, Pagination],
    spaceBetween: 30,
    centeredSlides: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    getSwiper: updateSwiper
  };

  return (
    <Container className={`${s.section} ${flip && s.white}`}>
      <div className="container">
        <div className={s.mainTitleWrapper}>
          <div className={s.titleContainer}>
            <Headline
              className={s.mainTitle}
              h2
              wild
              html={props.title.text_line1}
            />
            <Headline
              className={`${s.mainTitle} ${s.line2}`}
              h2
              wild
              html={props.title.text_line2}
            />
          </div>
          <div className={s.subWrap}>
            {props.subtitle.map(({ text_line }, i) => (
              <Headline
                h2
                key={text_line}
                className={s.mainSubTitle}
                html={text_line}
              />
            ))}
          </div>
        </div>
        <p
          className={s.titleText}
          dangerouslySetInnerHTML={{ __html: props.text }}
        />
      </div>
      <Swiper {...params}>
        {data.map((el, idx) => (
          <div key={idx} className={s.slide}>
            <div
              className={`${s.imgWrapper} ${
                flip === true ? `${s.imgFlip}` : null
              }`}
            >
              <Img
                className={s.image}
                src={el.image.title}
                alt={el.image.title}
              />
              <div
                className={`${s.subtitleWrapper} ${
                  flip ? s.subWrapperFlip : ''
                }`}
              >
                {el.simple_title.map((li2, idx) => (
                  <p
                    key={idx}
                    className={`${s.subtitle} ${s.sub1}`}
                    dangerouslySetInnerHTML={{ __html: li2.text_line }}
                  />
                ))}
                <Button
                  className={`${s.button} ${flip && s.buttonFlip}`}
                  href={el.href}
                >
                  {el.button.text}
                </Button>
              </div>
            </div>
            <div
              className={`${s.textWrapper} ${
                flip === true ? `${s.textWrapperFlip}` : null
              } `}
            >
              <div className={s.headlineWrapper}>
                <Headline
                  h2
                  wild
                  className={s.headline}
                  html={el.wild_title.text_line1}
                />
                <Headline
                  h2
                  wild
                  className={`${s.headline} ${s.slideHeadline2}`}
                  html={el.wild_title.text_line2}
                />
                <Headline
                  h2
                  wildExtra
                  white
                  className={`${s.underline} ${flip && s.flip}`}
                  html={flip ? 'P' : 'D'}
                />
              </div>
              <p
                className={`${s.paragraph} ${
                  flip ? `${s.paragraphFlip}` : null
                } `}
                dangerouslySetInnerHTML={{ __html: el.paragraph1 }}
              />
              <p
                className={`${s.paragraph} ${
                  flip ? `${s.paragraphFlip}` : null
                } `}
                dangerouslySetInnerHTML={{ __html: el.paragraph2 }}
              />
            </div>
          </div>
        ))}
      </Swiper>
      <Icon
        onClick={goPrev}
        icon="slider_arrow"
        className={`swiper-custom-prev ${s.icon} ${s.icnLeft}`}
      />
      <Icon
        onClick={goNext}
        icon="slider_arrow"
        className={`swiper-custom-next ${s.icon} ${s.icnright}`}
      />
    </Container>
  );
}
